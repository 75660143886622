import H from "history";
export const urls = {
    login: () => "/login",

    assessementList: () => "/app/assessement/list",
    assessementAdd: () => "/app/assessement/add",
    assessementEdit: () => `/app/assessement/edit/:id`,

    equipmentEdit: () => `/app/equipment/edit/:id`,
    equipmentAdd: () => "/app/equipment/add",
    equipmentList: () => "/app/equipment/list",

    userList: () => `/app/users/list`,
    userEdit: () => `/app/users/edit/:id`,

    jointsList: () => "/app/joints/list",
    jointsAdd: () => "/app/joints/add",
    jointsEdit: () => `/app/joints/edit/:id`,

    routinesList: () => "/app/routines/list",
    routinesEdit: () => `/app/routines/edit/:id`,
    routinesAdd: () => `/app/routines/add`,

    routinesTypeList: () => "/app/routines-type/list",
    routineTypeAdd: () => "/app/routines-type/add",
    routinesTypeEdit: () => `/app/routines-type/edit/:id`,

    programsList: () => "/app/programs/list",
    programsEdit: () => `/app/programs/edit/:id`,
    programsAdd: () => `/app/programs/add`,

    programsTypeList: () => "/app/programs-type/list",
    programsTypeAdd: () => "/app/programs-type/add",
    programsTypeEdit: () => `/app/programs-type/edit/:id`,

    phaseAdd: () => `/app/phase/add/:idProgram`,
    phaseEdit: () => `/app/phase/edit/:id/:idProgram`,

    exerciseTypeList: () => "/app/exercise-type/list",
    exerciseTypeAdd: () => "/app/exercise-type/add",
    exerciseTypeEdit: () => `/app/exercise-type/edit/:id`,

    exercisesList: () => "/app/exercises/list",
    exercisesAdd: () => "/app/exercises/add",
    exercisesEdit: () => `/app/exercises/edit/:id`,

    progressionList: () => "/app/progression/list",
    progressionAdd: () => "/app/progression/add",
    progressionEdit: () => `/app/exercise/edit/:id`
};
export const startUrl = urls.userList;

export function routeTo<T>(p: H.History, fn: () => string): void
export function routeTo<T>(p: H.History, fn: (p: T) => string, params: T): void
export function routeTo<T>(p: H.History, fn: (p?: T) => string, params?: T) {
    p.push(fn(params));
}
export function route(fn: () => string): string
export function route<T>(fn: (p: T) => string, params: Array<keyof T>): string
export function route<T>(fn: (p: T) => string, params: Array<keyof T> = []) {
    const parameter = Object.fromEntries(params.map(p => [p, ":" + String(p)]));
    return fn(parameter as any);
}
