import { Assessments } from "../models/Assessments";
import { Assessment } from "../models/Assessment";
import { AssessmentPayload } from "../models/AssessmentPayload";
import { baseUrl, headers } from "./config";
import { fetchAndParse, addQueryParameter } from "./utils";
export function getApiAssessmentList(): Promise<Assessments> {
    const url = new URL(`/api/assessment/list`, baseUrl);
    return fetchAndParse<Assessments>(url.toString(), { method: "GET", headers });
}
export function getApiAssessmentAssessmentid(assessmentId: number): Promise<Assessment> {
    const url = new URL(`/api/assessment/${assessmentId}`, baseUrl);
    return fetchAndParse<Assessment>(url.toString(), { method: "GET", headers });
}
export function putApiAssessmentAssessmentid(assessmentId: number, body?: AssessmentPayload): Promise<Assessment> {
    const url = new URL(`/api/assessment/${assessmentId}`, baseUrl);
    return fetchAndParse<Assessment>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers });
}
export function deleteApiAssessmentAssessmentid(assessmentId: number): Promise<boolean> {
    const url = new URL(`/api/assessment/${assessmentId}`, baseUrl);
    return fetchAndParse<boolean>(url.toString(), { method: "DELETE", headers });
}
export function postApiAssessment(body?: AssessmentPayload): Promise<Assessment> {
    const url = new URL(`/api/assessment`, baseUrl);
    return fetchAndParse<Assessment>(url.toString(), { method: "POST", body: JSON.stringify(body), headers });
}
