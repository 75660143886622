import { useState, useRef } from 'react'
import { useNavigate } from "react-router";
import { deleteApiWorkoutWorkoutid, getApiWorkoutList } from '../services/workout';
import MaterialTable, { Options } from '@material-table/core';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import { urls, route } from '../route';
import { loadTableData } from './loaders';
import { Workout } from '../models/Workout';
import { confirmAndDelete } from '../utils';
import Checkbox from '@mui/material/Checkbox';
import { kind } from '../models/kind-model';
export const RoutinesList = () => {
    const history = useNavigate();
    const ref = useRef<any>();
    const [hidden, setHidden] = useState(false)
    const [pagination,] = useState<Options<Workout>>({
        actionsColumnIndex: -1,
        selection: true,
        maxColumnSort: 0,
        pageSize: 10,
        columnsButton: true,
    });
    return <>
        <MaterialTable<Workout>
            title="Routines"
            options={pagination}
            tableRef={ref}
            columns={[
                { title: "Id", field: "id", cellStyle: { width: "5%" } },
                { title: "Name", field: "name", cellStyle: { width: "10%" } },
                { title: "Details", field: "details", cellStyle: { width: "35%" } },
                { title: "Has Progression", render: (e: Workout) => <>{e.type?.hasProgression ? "Y" : "N"}</>, cellStyle: { width: "10%", textAlign: "center" } },
                { title: "Duration", field: "duration", cellStyle: { width: "10%" } },
                { title: "Joints", render: (e: Workout) => <>{e.joints?.map(e => <div key={e.id}>{e.name}</div>)}</>, cellStyle: { width: "15%" } },
                { title: "Type", render: (e: Workout) => <><div>{e.type?.name}</div></>, cellStyle: { width: "15%" } },
                {
                    title: 'Tools',
                    render: (e) => (<>
                        <div style={{ float: "left" }}>
                            <div style={{ float: e.hidden ? "right" : "left", width: "50%", visibility: e.hidden ? "visible" : "hidden", backgroundColor: "#e6e6e6", textAlign: "center", fontWeight: "bold" }}>
                                <span style={{ writingMode: "vertical-rl", textOrientation: "upright" }}>HIDDEN</span>
                            </div>
                            <div style={{ float: e.hidden ? "left" : "right", width: "50%" }}>
                                <IconButton onClick={() => history(`/app/routines/edit/${e.id}`)} style={{ float: "right" }} ><Icon>edit</Icon></IconButton>
                                <IconButton onClick={confirmAndDelete(e, deleteApiWorkoutWorkoutid, ref)} style={{ float: "right" }}><Icon>delete</Icon></IconButton>
                            </div>
                        </div>
                    </>), cellStyle: { width: "5%" }
                }
            ]}
            data={loadTableData(() => getApiWorkoutList({ kind: [kind.routines], includeHidden: hidden }))}
            actions={[{
                icon: "delete",
                tooltip: "Delete",
                onClick: () => { }
            }, {
                icon: "visibilityOff",
                tooltip: "View Hidden",
                isFreeAction: true,
                onClick: () => { }

            }, {
                icon: "add",
                tooltip: "Add New",
                isFreeAction: true,
                onClick: () => history(route(urls.routinesAdd)),
            }]}
            components={{
                Action: props => {
                    if (props.action.icon === "visibilityOff")
                        return <Checkbox inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} value={hidden} checked={hidden}
                            onClick={() => {
                                setHidden(!hidden)
                                if (ref.current) {
                                    ref.current.onQueryChange();
                                }
                            }} />
                    else if (props.action.icon === "delete") {
                        const element = props.action;
                        return <IconButton aria-label={element.icon} size="small"
                            onClick={async () => {
                                const confirmation = window.confirm("Are you sure you want to delete the selected items?")
                                if (confirmation && Array.isArray(props.data)) {
                                    for (const i of props.data) {
                                        await deleteApiWorkoutWorkoutid(i.id);
                                    }
                                    if (ref.current) {
                                        ref.current.onQueryChange();
                                    }
                                }
                            }}>
                            <Icon>{element.icon}</Icon>
                        </IconButton>
                    }
                    else {
                        const element = props.action;
                        return <IconButton aria-label={element.icon} size="small"
                            onClick={element.onClick}>
                            <Icon>{element.icon}</Icon>
                        </IconButton>
                    }
                }
            }}
        />
    </>
}