import { useState } from 'react'
import { useNavigate } from 'react-router'
import { useLoadData } from '../utils'
import { getApiEquipmentEquipmentid, putApiEquipmentEquipmentid, postApiEquipment } from '../services/equipment';
import { Equipment } from '../models/Equipment';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material//Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Formik, Form } from 'formik';
import { urls, route } from '../route';
import { PartialBy } from '../services/utils';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
        },
    }),
);
export const EquipmentEdit = () => {
    const classes = useStyles();
    const history = useNavigate();
    const p = useParams();
    const [equipment, setEquipment] = useState<Equipment>();
    useLoadData(async () => {
        if (p.id) {
            const result = await getApiEquipmentEquipmentid(+p.id);
            setEquipment(result);
        } else {
            setEquipment({
                id: 0,
                name: "",
            })
        }
    });
    if (!equipment) {
        return <div></div>;
    }
    async function onSubmit(e: PartialBy<Equipment, 'id'>) {
        delete e.id;
        if (p.id) {
            await putApiEquipmentEquipmentid(+p.id, e);
        } else {
            await postApiEquipment(e);
        }
        history(route(urls.equipmentList));
    }
    return <Container>
        <Paper className={classes.root}>
            <Formik<Equipment>
                initialValues={equipment}
                onSubmit={onSubmit}
            >
                {({ values, handleChange }) => {
                    return <Form>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4" gutterBottom>
                                    {p.id ? `Edit ${equipment.name}` : "Add new Equipment"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={6}>
                                    <Grid item sm={4}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Button
                                            onClick={e => {
                                                history(route(urls.equipmentList));
                                            }}
                                            fullWidth
                                            variant="outlined"
                                            color="primary"
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid style={{ margin: 0 }} container spacing={3}>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Name"
                                        variant="outlined"
                                        value={values.name}
                                        id={"name"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                }}
            </Formik>
        </Paper>
    </Container>
};