import { useState, useRef } from 'react'
import { useNavigate } from "react-router";
import MaterialTable, { Options } from '@material-table/core';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import { urls, route } from '../route';
import { deleteApiWorkout_typeWorkouttypeid } from '../services/workout.type';
import { WorkoutType } from '../models/WorkoutType';
import { loadTableData } from './loaders';
import { confirmAndDelete } from '../utils';
import { getApiPrograms_typeList } from '../services/workout-type-filter';
export const ProgramsTypesList = () => {
    const history = useNavigate();
    const ref = useRef<any>();
    const [pagination,] = useState<Options<WorkoutType>>({
        actionsColumnIndex: -1,
        selection: true,
        sorting: false,
        pageSize: 10,
    });
    return <div>
        <MaterialTable<WorkoutType>
            title="Programs Types"
            options={pagination}
            tableRef={ref}
            columns={[
                { title: "Id", field: "id", cellStyle: { width: "5%" } },
                { title: "Name", field: "name", width: "90%" },
                {
                    title: 'Tools',
                    render: (e) => (<>
                        <IconButton onClick={() => history(`/app/programs-type/edit/${e.id}`)} style={{ float: "right" }}><Icon>edit</Icon></IconButton>
                        <IconButton onClick={confirmAndDelete(e, deleteApiWorkout_typeWorkouttypeid, ref)} style={{ float: "right" }}><Icon>delete</Icon></IconButton>
                    </>)
                }
            ]}
            data={loadTableData(getApiPrograms_typeList)}
            actions={[{
                icon: "delete",
                tooltip: "Delete",
                onClick: async (event, data) => {
                    const confirmation = window.confirm("Are you sure you want to delete the selected items?")
                    if (confirmation && Array.isArray(data)) {
                        for (const i of data) {
                            await deleteApiWorkout_typeWorkouttypeid(i.id);
                        }
                        if (ref.current) {
                            ref.current.onQueryChange();
                        }
                    }
                }
            }, {
                icon: "add",
                tooltip: "Add New",
                isFreeAction: true,
                onClick: () => history(route(urls.programsTypeAdd)),
            }]}
        />
    </div>
}