import { useState, useRef } from 'react'
import { useNavigate } from "react-router";
import MaterialTable, { Options } from '@material-table/core';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import { urls, route } from '../route';
import { loadTableData } from './loaders';
import Checkbox from '@mui/material/Checkbox';
import { Workout } from '../models/Workout';
import { deleteApiWorkoutWorkoutid, getApiWorkoutList } from '../services/workout';
import { confirmAndDelete } from '../utils';
import { kind } from '../models/kind-model';
import ReactExport from "react-export-excel";
import icon from "../export-excel.png";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const download = async () => {
    const data = await getApiWorkoutList({ kind: [kind.exercises], includeHidden: true, includeStats: true })
    const excelFile = new ExcelFile({
        filename: "Excercises",
        fileExtension: "xlsx",
        children: [<ExcelSheet data={data.items.map(exercise => ({
            name: exercise.name,
            totalDMTFailed: exercise.stats?.totalDMTFailed,
            totalDMTs: exercise.stats?.totalDMTs,
            type: exercise.type?.name,
            joints: exercise.joints?.map(joint => joint.name).toString()
        }))} name="Employees">
            <ExcelColumn label="Name" value="name" />
            <ExcelColumn label="Joint(s)" value="joints" />
            <ExcelColumn label="DMT X" value="totalDMTFailed" />
            <ExcelColumn label="DMT #" value="totalDMTs" />
            <ExcelColumn label="Type" value="type" />
        </ExcelSheet>]
    });
    excelFile.handleDownload();
};
export const ExercisesList = () => {
    const history = useNavigate();
    const ref = useRef<any>();
    const [hidden, setHidden] = useState(false)
    const [pagination,] = useState<Options<Workout>>({
        actionsColumnIndex: -1,
        selection: true,
        maxColumnSort: 0,
        pageSize: 10,
        columnsButton: true,
    });

    return <div>
        <MaterialTable<Workout>
            title="Exercises"
            options={pagination}
            tableRef={ref}
            columns={[
                { title: "Id", field: "id", cellStyle: { width: "5%" } },
                { title: "Name", field: "name", cellStyle: { width: "10%" } },
                { title: "Details", field: "details", cellStyle: { width: "20%" } },
                { title: "DMT X", field: "stats.totalDMTFailed", cellStyle: { width: "5%" } },
                { title: "DMT #", field: "stats.totalDMTs", cellStyle: { width: "5%" } },
                { title: "Joints", render: (e: Workout) => <>{e.joints?.map(e => <div key={e.id}>{e.name}</div>)}</>, cellStyle: { width: "10%" } },
                { title: "Equipments", render: (e: Workout) => <>{e.equipments?.map(e => <div key={e.id}>{e.name}</div>)}</>, cellStyle: { width: "10%" } },
                { title: "Type", render: (e: Workout) => <><div>{e.type?.name}</div></>, cellStyle: { width: "10%" } },
                { title: "Progressions", render: (e: Workout) => <>{e.progression?.progression?.map((e) => <div key={e.order}>{e.order}  {e.reps ? `Reps: ${e.reps}` : ""} {e.sets ? `Sets: ${e.sets}` : ""}  {e.hold ? `Hold: ${e.hold}` : ""} {e.cycles ? `Cycles: ${e.cycles}` : ""}  <br /></div>)}</>, cellStyle: { width: "20%" } },
                {
                    title: "Tools",
                    render: (e) => (<>
                        <div style={{ float: "left" }}>
                            <div style={{ float: e.hidden ? "right" : "left", width: "50%", visibility: e.hidden ? "visible" : "hidden", backgroundColor: "#e6e6e6", textAlign: "center", fontWeight: "bold" }}>
                                <span style={{ writingMode: "vertical-rl", textOrientation: "upright" }}>HIDDEN</span>
                            </div>
                            <div style={{ float: e.hidden ? "left" : "right", width: "50%" }}>
                                <IconButton onClick={() => history(`/app/exercises/edit/${e.id}`)} style={{ float: "right" }}><Icon>edit</Icon></IconButton>
                                <IconButton onClick={confirmAndDelete(e, deleteApiWorkoutWorkoutid, ref)} style={{ float: "right" }}><Icon>delete</Icon></IconButton>
                            </div>
                        </div>
                    </>), cellStyle: { width: "5%" }
                }
            ]}
            data={loadTableData(() => getApiWorkoutList({ kind: [kind.exercises], includeHidden: hidden, includeStats: true }))}
            actions={[{
                icon: "delete",
                tooltip: "Delete",
                onClick: () => { }
            }, {
                icon: "visibilityOff",
                tooltip: "View Hidden",
                isFreeAction: true,
                onClick: () => { }

            }, {
                tooltip: "Export CSV",
                isFreeAction: true,
                disabled: false,
                icon: "export-csv",
                onClick: download
            }, {
                icon: "add",
                tooltip: "Add New",
                isFreeAction: true,
                onClick: () => history(route(urls.exercisesAdd)),
            }]}
            components={{
                Action: props => {
                    if (props.action.icon === "visibilityOff")
                        return <Checkbox inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} value={hidden} checked={hidden}
                            onClick={() => {
                                setHidden(!hidden)
                                if (ref.current) {
                                    ref.current.onQueryChange();
                                }
                            }} />
                    else if (props.action.icon === "export-csv") {
                        return <IconButton onClick={props.action.onClick} size="small">
                            <img alt='export-excel' src={String(icon)} style={{
                                width: "24px",
                                height: "24px",
                            }} />
                        </IconButton>
                    } else if (props.action.icon === "delete") {
                        const element = props.action;
                        return <IconButton key={element.icon} aria-label={element.icon} size="small"
                            onClick={async () => {
                                const confirmation = window.confirm("Are you sure you want to delete the selected items?")
                                if (confirmation && Array.isArray(props.data)) {
                                    for (const i of props.data) {
                                        await deleteApiWorkoutWorkoutid(i.id);
                                    }
                                    if (ref.current) {
                                        ref.current.onQueryChange();
                                    }
                                }
                            }}>
                            <Icon>{element.icon}</Icon>
                        </IconButton>
                    }
                    else {
                        const element = props.action;
                        return <IconButton aria-label={element.icon} size="small"
                            onClick={element.onClick}>
                            <Icon>{element.icon}</Icon>
                        </IconButton>
                    }
                }
            }}
        />
    </div>
}