import { useState } from 'react'
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { Formik, Form } from 'formik';
import { urls, route } from '../route';
import { useNavigate } from 'react-router-dom';
import { UserProfilePayload } from '../models/UserProfilePayload';
import { useLoadData } from '../utils';
import { postApiUserUpdate, getApiUserUserid } from '../services/user';
import { UpdateUserPayload } from '../models/UpdateUserPayload';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
        },
    }),
);
export const UserEdit = () => {
    const classes = useStyles();
    const history = useNavigate();
    const p = useParams();
    const [noDate, setNoDate] = useState(false)
    const [emailNotification, setEmailNotifications] = useState(false);
    const [pushNotification, setpushNotification] = useState(false);
    const [user, setUser] = useState<UserProfilePayload>();

    useLoadData(async () => {
        const result = await getApiUserUserid(+p.id!);
        setNoDate(result.licenceExpiration ? false : true)
        setEmailNotifications(result.profile?.emailNotification!)
        setpushNotification(result.profile?.pushNotification!)
        setUser({ ...result, licenceExpiration: (result.licenceExpiration ?? new Date().toISOString()).split("T")[0] });
    });

    async function onSubmit(e: UpdateUserPayload) {
        try {
            if (p.id) {
                await postApiUserUpdate({
                    id: +p.id,
                    licenceExpiration: noDate ? undefined : new Date(e?.licenceExpiration ?? "").toISOString(),
                    emailNotification: emailNotification,
                    pushNotification: pushNotification
                });
            }
            history(route(urls.userList));
        } catch (e) {
            console.log(e)
        }
    }
    if (!user)
        return <div></div>
    return <Container>
        <Paper className={classes.root}>
            <Formik<UserProfilePayload>
                initialValues={user}
                onSubmit={onSubmit}
            >
                {({ values, handleChange, setFieldValue }) => {
                    return <Form>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4" gutterBottom>
                                    {p.id ? `Edit ${user.email}` : ""}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={6}>
                                    <Grid item sm={4}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Button
                                            onClick={e => {
                                                history(route(urls.userList));
                                            }}
                                            fullWidth
                                            variant="outlined"
                                            color="primary"
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid style={{ margin: 0 }} container spacing={3}>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        disabled={noDate}
                                        fullWidth
                                        type="date"
                                        label="Licence"
                                        variant="outlined"
                                        value={values.licenceExpiration ?? new Date().toISOString().split('T')[0]}
                                        id={"licenceExpiration"}
                                        onChange={(event) => {
                                            console.log(event.target.value);
                                            setFieldValue("licenceExpiration", event.target.value.toString())
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div style={{ float: "left", paddingTop: "2.5%" }}>
                                        No licence: <Checkbox inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} value={noDate} checked={noDate} onChange={() => { setNoDate(!noDate) }} />
                                    </div>
                                </Grid>
                                <Grid container spacing={3} style={{ margin: 0, marginBottom: "10px" }}>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            label="Subscription Type"
                                            variant="outlined"
                                            value={!noDate ? (user.subscription?.product_id ?? "CUSTOM") : "-"}
                                            id={"subscriptionType"}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Grid><Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            label="Subscription ID"
                                            variant="outlined"
                                            value={!noDate ? (user.subscription?.token ?? "-") : "-"}
                                            id={"subscriptionId"}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={6} style={{ marginLeft: "10px" }}>
                                    <Grid item xs={12} sm={4}>
                                        Email notifications:
                                        <Checkbox
                                            inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                                            value={user!.profile!.emailNotification}
                                            checked={emailNotification}
                                            onChange={() => {
                                                setEmailNotifications(!emailNotification)
                                            }} />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        Push notifications:
                                        <Checkbox
                                            inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                                            value={user!.profile!.pushNotification}
                                            checked={pushNotification}
                                            onChange={() => {
                                                setpushNotification(!pushNotification)
                                            }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                }}
            </Formik>
        </Paper>
    </Container>
};
