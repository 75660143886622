import { QueryResult, Query } from '@material-table/core';

function arrayContainsText(array: string[], text: string) {
    return array.reduce((r, key) => r && text.toLocaleLowerCase().includes(key), true as boolean);
}
type SearchFunction<T> = () => Promise<{ items: T[], }>
export function loadTableData<T extends { name?: string, email?: string }>(fn: SearchFunction<T>) {
    return async function (q: Query<T>): Promise<QueryResult<T>> {
        const result = await fn();
        if (q.search) {
            let set = new Set()
            result.items.forEach((str) => {
                const keys = q.search.toLocaleLowerCase().split(" ");
                let stringified = "";
                try {
                    stringified = JSON.stringify(str);
                } catch (e) {
                    console.warn(e);
                }
                const existsInRow = arrayContainsText(keys, stringified)
                if (existsInRow) {
                    set.add(str);
                }
                if (str.name && str.name.toLowerCase().indexOf(q.search.toLowerCase()) !== -1) {
                    set.add(str)
                }
                if (str.email && str.email.toLowerCase().indexOf(q.search.toLowerCase()) !== -1) {
                    set.add(str)
                }
            })
            const filterResults = Array.from(set) as T[]

            return {
                data: filterResults.slice(q.page * q.pageSize, (q.page + 1) * q.pageSize) || [],
                page: q.page,
                totalCount: filterResults.length,
            };
        }
        else {
            return {
                data: result.items.slice(q.page * q.pageSize, (q.page + 1) * q.pageSize) || [],
                page: q.page,
                totalCount: result.items.length,
            };
        }

    }
}
// const filterResults = result.items.filter((str) => str.name ? str.name.toLowerCase().indexOf(q.search.toLowerCase()) !== -1 : "")