import { Equipments } from "../models/Equipments";
import { Equipment } from "../models/Equipment";
import { EquipmentPayload } from "../models/EquipmentPayload";
import { baseUrl, headers } from "./config";
import { fetchAndParse, addQueryParameter } from "./utils";
export function getApiEquipmentList(): Promise<Equipments> {
    const url = new URL(`/api/equipment/list`, baseUrl);
    return fetchAndParse<Equipments>(url.toString(), { method: "GET", headers });
}
export function getApiEquipmentEquipmentid(equipmentId: number): Promise<Equipment> {
    const url = new URL(`/api/equipment/${equipmentId}`, baseUrl);
    return fetchAndParse<Equipment>(url.toString(), { method: "GET", headers });
}
export function putApiEquipmentEquipmentid(equipmentId: number, body?: EquipmentPayload): Promise<Equipment> {
    const url = new URL(`/api/equipment/${equipmentId}`, baseUrl);
    return fetchAndParse<Equipment>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers });
}
export function deleteApiEquipmentEquipmentid(equipmentId: number): Promise<boolean> {
    const url = new URL(`/api/equipment/${equipmentId}`, baseUrl);
    return fetchAndParse<boolean>(url.toString(), { method: "DELETE", headers });
}
export function postApiEquipment(body?: EquipmentPayload): Promise<Equipment> {
    const url = new URL(`/api/equipment`, baseUrl);
    return fetchAndParse<Equipment>(url.toString(), { method: "POST", body: JSON.stringify(body), headers });
}
