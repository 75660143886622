import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { makeStyles, createStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Checkbox } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import { useLoadData } from '../utils';
import { getApiWorkoutWorkoutid, putApiWorkoutWorkoutid, postApiWorkout, putApiWorkoutWorkoutidPicture } from '../services/workout';
import { WorkoutPayload } from '../models/WorkoutPayload';
import { WorkoutType } from '../models/WorkoutType';
import { Formik, Form, FieldArray } from 'formik';
import { urls, route } from '../route';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { getApiJointList } from '../services/joint';
import { AsyncAutocomplete } from '../components/asyncAutocomplete';
import { getApiEquipmentList } from '../services/equipment';
import { Workout } from '../models/Workout'
import { kind } from '../models/kind-model'
import { WorkoutWithInfo } from '../models/workout-info-and-progression'
import { getApiExercise_typeList } from '../services/workout-type-filter';
import { getApiWorkout_progressionProgressionid } from '../services/workout.progression';
import { WorkoutTypeInfoWithProgression } from '../models/workout-info-and-progression';
import { WorkoutChildernPayload } from '../models/WorkoutChildernPayload';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: { spacing: (arg0: number, arg1: number) => any; }) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
        },
    }),
);
export const ExercisesEdit = () => {
    const history = useNavigate();
    const p = useParams();
    const classes = useStyles();
    const [exercise, setExercise] = useState<WorkoutTypeInfoWithProgression>();
    const [url, setUrl] = useState("");
    useLoadData(async () => {
        if (p.id) {
            const result = await getApiWorkoutWorkoutid(+p.id, {});
            setExercise(result);
            if (result.type!.isCustomProgression && result.progression?.id) {
                const progression = await getApiWorkout_progressionProgressionid(result.progression?.id)
                setExercise({ ...result, progression: { ...progression.progression } })
            }
            setImgUrl(result.picture ?? "");
            const blob = new Blob([result.dmtTechniqueCues ?? ""], { type: "text/html" })
            const url = URL.createObjectURL(blob)
            setUrl(url)
        } else {
            setExercise({
                workoutTypeId: {
                    id: 0,
                    name: "",
                    hasProgression: false,
                    isCustomProgression: false,
                    noSchedule: false
                },
                hidden: false,
                includeInDmt: false,
                durationMin: 0,
                durationMax: 0,
                kind: kind.exercises,
                level: 0,
                mediaKey: "",
                name: "",
                id: 1,
                type: {
                    id: 1,
                    name: "",
                    videoMode: 0,
                    hasProgression: false,
                    isCustomProgression: false,
                    noSchedule: false
                },
            });
        }
    });
    const [imgUrl, setImgUrl] = useState<string>("");
    if (!exercise) {
        return <div></div>;
    }
    async function onSubmit(workoutPayload: Workout) {
        const joints = workoutPayload.joints?.map(e => {
            if (typeof e !== "number") {
                return e.id;
            }
            return e;
        }) ?? [];
        const equipments = workoutPayload.equipments?.map(e => {
            if (typeof e !== "number") {
                return e.id;
            }
            return e;
        }) ?? [];
        const workoutChildren: WorkoutChildernPayload[] = [];
        const videoDuration = workoutPayload.videoDuration ?? 0
        const duration = workoutPayload.duration ?? 0
        const downloadUrl = workoutPayload.downloadUrl ?? ""
        const details = workoutPayload.details ?? ""

        const progression = workoutPayload.type!.isCustomProgression ? {
            type: workoutPayload.type!.name
            , progression: workoutPayload.progression?.progression?.map(e => ({
                reps: e.reps === 0 || e.reps === "" ? undefined : e.reps,
                sets: e.sets === 0 || typeof e.sets == "string" ? undefined : e.sets,
                cycles: e.cycles === 0 || typeof e.cycles == "string" ? undefined : e.cycles,
                hold: e.hold === 0 || typeof e.hold == "string" ? undefined : e.hold,
                order: e.order
            })),
            id: workoutPayload.progression?.id
        } : {
            type: workoutPayload.type!.name ?? workoutPayload.progression?.type,
            id: workoutPayload.progression?.id,
            progression: workoutPayload.progression?.progression
        }

        const workoutTypeId = workoutPayload.type!.id
        const workout = { ...workoutPayload, joints, equipments, workoutTypeId, workoutChildren, progression, videoDuration, duration, downloadUrl, details, relatedWorkouts: undefined, dmtNumberOfReps: workoutPayload.includeInDmt ? workoutPayload.dmtNumberOfReps : undefined }

        var { type, id, scheduled, userWorkout, isCustom, workoutProgression, childCount, grandChildCount, ...payload } = workout;

        if (p.id) {
            await putApiWorkoutWorkoutid(+p.id, payload);
        } else {
            await postApiWorkout(payload);

        }
        history(route(urls.exercisesList));
    }
    return <Container>
        <Paper className={classes.root}>
            <Formik<WorkoutWithInfo>
                initialValues={exercise}
                onSubmit={onSubmit}
            >
                {({ values, handleChange, setValues }) => {
                    function handleChangeHTML(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
                        const value = event.target.value
                        const blob = new Blob([value], { type: "text/html" })
                        const url = URL.createObjectURL(blob)
                        setUrl(url)
                        setValues({ ...values, dmtTechniqueCues: event.target.value })
                    }
                    const handleCapture = async (e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.files) {
                            const file = e.target.files[0];
                            const result = await putApiWorkoutWorkoutidPicture(values.id, file);
                            setValues({ ...values, picture: result.picture });
                            setImgUrl(result.picture);
                        }
                    }
                    const handleAsyncChange = (key: keyof WorkoutPayload, value: number | number[]) => {
                        setValues({ ...values, [key]: value });
                    }
                    const forField = <K extends keyof WorkoutPayload>(key: K) => ({
                        handleAsyncChange,
                        defaultValue: values[key],
                        searchWord: key
                    })
                    return <Form>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4" gutterBottom>{p.id ? `Editing ${exercise.name}` : "Add new Exercise"}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={6}>
                                    <Grid item sm={4}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Button
                                            onClick={_e => {
                                                history(route(urls.exercisesList));
                                            }}
                                            fullWidth
                                            variant="outlined"
                                            color="primary"
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div style={{ float: "right" }}>
                                            DMT: <Checkbox id={"includeInDmt"} inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} value={values.includeInDmt} checked={values.includeInDmt} onChange={handleChange} />
                                        </div>
                                        <div style={{ float: "right" }}>
                                            Hide: <Checkbox id={"hidden"} inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} value={values.hidden} checked={values.hidden} onChange={handleChange} />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid style={{ margin: 0 }} container spacing={3}>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Name"
                                        variant="outlined"
                                        value={values.name}
                                        id={"name"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Media ID"
                                        variant="outlined"
                                        value={values.mediaKey || ""}
                                        id={"mediaKey"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Download Url"
                                        variant="outlined"
                                        value={values.downloadUrl || ""}
                                        id={"downloadUrl"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} >
                                    <AsyncAutocomplete
                                        label="Type"
                                        multiple={false}
                                        suggestions={getApiExercise_typeList}
                                        defaultValue={values.type}
                                        searchWord="type"
                                        handleAsyncChange={(_key, _v, items) => {
                                            console.log("sugesstion", getApiExercise_typeList);
                                            const { progression, ...type } = items as WorkoutType;
                                            let newProgression = values.progression;
                                            if (!type.isCustomProgression && progression) {
                                                newProgression = { ...progression, type: type.name };
                                            } else if (((type.isCustomProgression && exercise.type?.isCustomProgression) && (items.name !== exercise.type.name)) ||
                                                (type.isCustomProgression && !exercise.type?.isCustomProgression)) {
                                                newProgression = { ...progression, type: type.name, id: undefined };
                                            }
                                            setValues({ ...values, type, progression: newProgression })

                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        required={values.includeInDmt ? true : false}
                                        fullWidth
                                        type="string"
                                        label="DMT No of reps"
                                        variant="outlined"
                                        value={values.includeInDmt ? (values.dmtNumberOfReps ?? "") : ""}
                                        id={"dmtNumberOfReps"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                {values.type!.isCustomProgression ?
                                    <Grid item xs={12} sm={8} >
                                        <FieldArray
                                            name="progression.progression"
                                            render={arrayHelpers => (
                                                <Grid container spacing={4}>
                                                    {values.progression?.progression && values.progression?.progression?.length > 0 ? (
                                                        values.progression?.progression.map((e, index: number) => (
                                                            <Grid item xs={12} key={index}>
                                                                Progression {e.order = index + 1}:
                                                                <TextField label="Reps" type="string" name={`progression.progression.${index}.reps`} value={e.reps ?? ""} onChange={handleChange} variant="outlined" style={{ marginLeft: '1em', marginRight: '1em', width: '5.5em' }} />
                                                                <TextField label="Sets" type="number" name={`progression.progression.${index}.sets`} value={e.sets ?? ""} onChange={handleChange} variant="outlined" style={{ marginLeft: '1em', marginRight: '1em', width: '5.5em' }} />
                                                                <TextField label="Hold" type="number" name={`progression.progression.${index}.hold`} value={e.hold ?? ""} onChange={handleChange} variant="outlined" style={{ marginLeft: '1em', marginRight: '1em', width: '5.5em' }} />
                                                                <TextField label="Cycles" type="number" name={`progression.progression.${index}.cycles`} value={e.cycles ?? ""} onChange={handleChange} variant="outlined" style={{ marginLeft: '1em', marginRight: '1em', width: '5.5em' }} />
                                                                <IconButton aria-label="delete" onClick={() => arrayHelpers.remove(index)}>
                                                                    <Icon>delete</Icon>
                                                                </IconButton>
                                                                {index + 1 === values.progression?.progression?.length && values.progression?.progression?.length !== 3 ? <IconButton aria-label="delete" onClick={() => arrayHelpers.insert(index + 1, { reps: undefined, sets: undefined, hold: undefined, cycles: undefined })}>
                                                                    <Icon>add</Icon>
                                                                </IconButton> : <div></div>}
                                                            </Grid>
                                                        ))
                                                    ) : (
                                                        <Grid item xs={12}>
                                                            <Button color="primary" variant="contained" onClick={() => arrayHelpers.push({ reps: undefined, sets: undefined, hold: undefined, cycles: undefined })}>
                                                                Add a progression
                                                            </Button>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            )}
                                        />

                                    </Grid> : ""}
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        required
                                        label="Details"
                                        variant="outlined"
                                        value={values.details || ""}
                                        id={"details"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <AsyncAutocomplete
                                        label="Joints"
                                        multiple={true}
                                        suggestions={getApiJointList}
                                        {...forField("joints")}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AsyncAutocomplete
                                        label="Equipments"
                                        multiple={true}
                                        suggestions={getApiEquipmentList}
                                        {...forField("equipments")}
                                    />
                                </Grid>

                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={4} >
                                        <div style={{ marginBottom: "1em" }}>
                                            <input
                                                accept="image/*"
                                                style={{ display: "none" }}
                                                id="raised-button-file"
                                                onChange={handleCapture}
                                                type="file"
                                            />
                                            <label htmlFor="raised-button-file">
                                                <Button variant="contained" component="span">
                                                    Upload Image <CloudUploadIcon style={{ paddingLeft: "0.2em" }} />
                                                </Button>
                                            </label>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid >
                                    <img alt="" src={`${imgUrl}?t=${Date.now()}`} width="400px" height="auto" />
                                </Grid>
                            </Grid>


                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6} >
                                        <TextField
                                            label="DMT Exercise instructions (HTML)"
                                            value={values.dmtTechniqueCues ?? ""}
                                            onChange={handleChangeHTML}
                                            variant="outlined"
                                            multiline
                                            fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <iframe title="htmlShow" src={url} width="100%" height="100%" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                }}
            </Formik>
        </Paper>
    </Container>
}