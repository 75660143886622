import { useEffect, useState } from 'react';
import { useLoadData } from '../utils';
import { Workout } from '../models/Workout';
import { WorkoutBasic } from '../models/WorkoutBasic';
import Autocomplete from '@mui/material/Autocomplete';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

export function AsyncAutoCompleteTable(p: {
    label: string,
    searchWord: keyof Workout,
    value: Record<keyof any, any>,
    suggestions: () => Promise<{ items?: Workout[] }>,

} & ({
    multiple: true,
    defaultValue?: WorkoutBasic[],
    onChange: (value: Workout) => void,
})) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<Workout[]>([]);
    const loading = open && options.length === 0;
    useLoadData(async () => {
        const response = await p.suggestions();
        setOptions(response.items || []);
    })
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let active = true;
        if (!loading) {
            return undefined;
        }
        return () => {
            active = false;
        };
    }, [loading]);
    if (options.length === 0) {
        return <div></div>;
    }
    return (
        <Autocomplete<Record<keyof any, any>, any, any, any>
            multiple={p.multiple || false}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            disableClearable
            getOptionLabel={(option) => (option as Workout).name}
            defaultValue={options.length && p.defaultValue && p.multiple ?
                (p.defaultValue
                    && Array.isArray(p.defaultValue)
                    && p.defaultValue.length > 0
                    ? p.defaultValue.map(e => options[options.map(o => o.id).indexOf(e.id)])! : [])
                : p.defaultValue!}
            onChange={(event, value: any) => {
                p.multiple && p.onChange(value[0]);
            }}
            options={options}
            loading={loading}
            value={p.value}
            renderOption={(props: any, options, state) => {
                const params: Workout = options as Workout;
                return <li {...props}>
                    <CardContent key={params.name} style={{ width: "100%", borderTopStyle: "solid", borderTopColor: "#D3D3D3", borderTopWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "#D3D3D3", borderBottomWidth: "1px" }}>
                        <Typography component="h2" style={{ fontSize: "1.2em" }}>
                            {params.name}
                        </Typography>
                        <Typography variant="body1" color="textSecondary" component="p" style={{ paddingLeft: "3em" }}>
                            {params.details}<br />
                            Joints: {params.joints?.map(e => <span key={e.id}>{e.name}  |   </span>)}<br />
                            Equipments: {params.equipments?.map(e => <span key={e.id}>{e.name}  |   </span>)}<br />
                            Type:   <span>{params.type?.name}</span><br />
                        </Typography>
                    </CardContent>
                </li>
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    label={p.label}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}
