import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLoadData } from '../utils'
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Formik, Form } from 'formik';
import { urls, route } from '../route';
import { getApiJointJointid, putApiJointJointid, postApiJoint } from '../services/joint';
import { JointPayload } from '../models/JointPayload';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
        },
    }),
);

type JointWithoutId = Omit<JointPayload, "id">;
export const JointEdit = () => {
    const classes = useStyles();
    const history = useNavigate();
    const p = useParams();
    const [joint, setJoint] = useState<JointWithoutId>();
    useLoadData(async () => {
        if (p.id) {
            const result = await getApiJointJointid(+p.id);
            setJoint(result);
        } else {
            setJoint({
                name: "",
            })
        }
    });
    if (!joint) {
        return <div></div>;
    }
    async function onSubmit(e: JointWithoutId) {
        try {
            if (p.id) {
                await putApiJointJointid(+p.id, { name: e.name });
            } else {
                await postApiJoint(e);
            }
            history(route(urls.jointsList));
        } catch (e) {

        }
    }
    return <Container>
        <Paper className={classes.root}>
            <Formik<JointWithoutId>
                initialValues={joint}
                onSubmit={onSubmit}
            >
                {({ values, handleChange }) => {
                    return <Form>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4" gutterBottom>
                                    {p.id ? `Edit ${joint.name}` : "Add new Joint"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={6}>
                                    <Grid item sm={4}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Button
                                            onClick={e => {
                                                history(route(urls.jointsList));
                                            }}
                                            fullWidth
                                            variant="outlined"
                                            color="primary"
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid style={{ margin: 0 }} container spacing={3}>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Name"
                                        variant="outlined"
                                        value={values.name}
                                        id={"name"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                }}
            </Formik>
        </Paper>
    </Container>
};