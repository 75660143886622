import { headers } from './config';
import { postApiUserLogin } from './user';
import { EmailSignIn } from '../models/alt-EmailSignIn';

export async function userLogin(options: EmailSignIn) {
    try {
        const result = await postApiUserLogin(options);
        Object.assign(headers, { Authorization: `${result.access_token}` });
        return { username: options.email, token: result.access_token as string };
    } catch (e: any) {
        throw new Error(e.message);
    }
}