import { WorkoutTypes } from "../models/WorkoutTypes";
import { baseUrl, headers } from "./config";
import { fetchAndParse } from "./utils";
import { kind } from "../models/kind-model";

export async function getApiRoutine_typeList(): Promise<WorkoutTypes> {
    const url = new URL(`/api/workout_type/list`, baseUrl);
     const workouts = await fetchAndParse<WorkoutTypes>(url.toString(), { method: "GET", headers });
     return {items:workouts.items!.filter(e => e.kind === kind.routines)}
}
export async function getApiExercise_typeList(): Promise<WorkoutTypes> {
    const url = new URL(`/api/workout_type/list`, baseUrl);
     const workouts = await fetchAndParse<WorkoutTypes>(url.toString(), { method: "GET", headers });
     return {items:workouts.items!.filter(e => e.kind === kind.exercises)}
}