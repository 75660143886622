import { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import { userLogin } from "../services/user.service";
import { localStorageUserKey } from "../services/config";
import { withPreventDefault, updateState } from "../utils";

export const LoginPage = () => {
    const x = ""
    const [user, setUser] = useState({ email: "", password: "", authType: 0 as const });
    const [error, setError] = useState("");
    let handleSubmit = async () => {
        try {
            let result = await userLogin(user);
            window.localStorage.setItem(localStorageUserKey, JSON.stringify(result));
            window.location.reload();
        } catch (e: any) {
            setError("Invalid Email or Password");
        }
    };
    return (
        <>
            <Container
                component="main"
                maxWidth="xs"
                style={{
                    backgroundColor: "rgba(255,255,255,0.2)",
                    padding: "1.5rem",
                    borderRadius: "0.5rem",
                    border: "2px solid #0c69b1",
                    boxShadow: "0 3px 20px rgba(0, 0, 0, 0.25)",
                    marginTop: "15%"
                }}
            >
                <div >
                    <form
                        onSubmit={withPreventDefault(handleSubmit)}
                    >
                        <TextField
                            variant="filled"
                            margin="normal"
                            required
                            fullWidth
                            id="user"
                            label="Email"
                            autoFocus
                            onChange={updateState(user, setUser, "email")}
                        />
                        <TextField
                            variant="filled"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={"Password"}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={updateState(user, setUser, "password")}
                        />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                        </div>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                        >
                            Sign in
                        </Button>
                    </form>
                    {error && (
                        <div>
                            <p>{error}</p>
                        </div>
                    )}
                </div>
            </Container>
        </>
    );
};