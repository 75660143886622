import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { makeStyles, createStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { useLoadData } from '../utils';
import { getApiWorkout_typeWorkouttypeid, putApiWorkout_typeWorkouttypeid, postApiWorkout_type } from '../services/workout.type';
import { WorkoutType } from '../models/WorkoutType';
import { Formik, Form } from 'formik';
import { urls, route } from '../route';
import { kind } from '../models/kind-model';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
        },
    }),
);
export const ProgramsTypesEdit = () => {
    const classes = useStyles();
    const history = useNavigate();
    const p = useParams();
    const [workout, setWorkout] = useState<WorkoutType>();
    const [noScheduleValue, setNoSchedule] = useState(false);

    useLoadData(async () => {
        if (p.id) {
            const result = await getApiWorkout_typeWorkouttypeid(+p.id);
            setWorkout(result);
            setNoSchedule(result.noSchedule);
        } else {
            setWorkout({
                hasProgression: false,
                id: 0,
                name: "",
                videoMode: 0,
                kind: kind.programs,
                isCustomProgression: false,
                editable: false,
                noSchedule: false
            });
        }
    });
    async function onSubmit(o: WorkoutType) {
        const workoutPayload = { ...o }
        var { id, editable, ...payload } = workoutPayload
        var newPayload = {...payload, noSchedule: noScheduleValue}

        if (p.id) {
            await putApiWorkout_typeWorkouttypeid(+p.id, newPayload);
        } else {
            await postApiWorkout_type(newPayload);
        }
        history(route(urls.programsTypeList));
    }
    if (!workout) {
        return <div></div>;
    }
    return <Container>
        <Paper className={classes.root}>
            <Formik<WorkoutType>
                initialValues={workout}
                onSubmit={onSubmit}
            >
                {({ values, handleChange, errors }) => {
                    return <Form>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4" gutterBottom>{p.id ? `Editing ${workout.name}` : "Add new Program Type"}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={6}>
                                    <Grid item sm={4}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Button
                                            onClick={e => {
                                                history(route(urls.programsTypeList));
                                            }}
                                            fullWidth
                                            variant="outlined"
                                            color="primary"
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid style={{ margin: 0 }} container spacing={3}>
                            <Grid item xs={12} sm={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Name"
                                        variant="outlined"
                                        value={values.name}
                                        id={"name"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <div style={{ float: "left", paddingTop: "5%" }}>
                                        No schedule: <Checkbox id={"hasProgression"} inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} value={values.noSchedule} checked={noScheduleValue} onChange={() => setNoSchedule(!noScheduleValue)} />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                }}
            </Formik>
        </Paper>
    </Container>
}