import { ProgramStatisticsResponse } from "../models/ProgramStatisticsResponse";
import { Workouts } from "../models/Workouts";
import { Workout } from "../models/Workout";
import { WorkoutPayload } from "../models/WorkoutPayload";
import { CustomWorkoutPayload } from "../models/CustomWorkoutPayload";
import { CustomWorkouts } from "../models/CustomWorkouts";
import { CustomWorkoutsResponse } from "../models/CustomWorkoutsResponse";
import { CustomWorkoutUpdatePayload } from "../models/CustomWorkoutUpdatePayload";
import { PictureResponse } from "../models/PictureResponse";
import { baseUrl, headers } from "./config";
import { appendFormData, fetchAndParse, addQueryParameter } from "./utils";
export function getApiStatisticsProgram({ programId, userId, routineId }: {
    programId?: number;
    userId?: number;
    routineId?: number;
}): Promise<ProgramStatisticsResponse> {
    const url = new URL(`/api/statistics/program`, baseUrl);
    addQueryParameter(url, "programId", programId);
    addQueryParameter(url, "userId", userId);
    addQueryParameter(url, "routineId", routineId);
    return fetchAndParse<ProgramStatisticsResponse>(url.toString(), { method: "GET", headers });
}
export function getApiWorkoutList({ kind, type, joint, durationMin, durationMax, favorites, skip, includeHidden, limit, includeStats }: {
    kind?: string[];
    type?: string[];
    joint?: string[];
    durationMin?: number;
    durationMax?: number;
    favorites?: boolean;
    skip?: number;
    includeHidden?: boolean;
    limit?: number;
    includeStats?: boolean;
}): Promise<Workouts> {
    const url = new URL(`/api/workout/list`, baseUrl);
    addQueryParameter(url, "kind", kind);
    addQueryParameter(url, "type", type);
    addQueryParameter(url, "joint", joint);
    addQueryParameter(url, "durationMin", durationMin);
    addQueryParameter(url, "durationMax", durationMax);
    addQueryParameter(url, "favorites", favorites);
    addQueryParameter(url, "skip", skip);
    addQueryParameter(url, "includeHidden", includeHidden);
    addQueryParameter(url, "limit", limit);
    addQueryParameter(url, "includeStats", includeStats);
    return fetchAndParse<Workouts>(url.toString(), { method: "GET", headers });
}
export function getApiWorkoutWorkoutid(workoutId: number, { scheduleWorkoutId }: {
    scheduleWorkoutId?: number;
}): Promise<Workout> {
    const url = new URL(`/api/workout/${workoutId}`, baseUrl);
    addQueryParameter(url, "scheduleWorkoutId", scheduleWorkoutId);
    return fetchAndParse<Workout>(url.toString(), { method: "GET", headers });
}
export function putApiWorkoutWorkoutid(workoutId: number, body?: WorkoutPayload): Promise<Workout> {
    const url = new URL(`/api/workout/${workoutId}`, baseUrl);
    return fetchAndParse<Workout>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers });
}
export function deleteApiWorkoutWorkoutid(workoutId: number): Promise<boolean> {
    const url = new URL(`/api/workout/${workoutId}`, baseUrl);
    return fetchAndParse<boolean>(url.toString(), { method: "DELETE", headers });
}
export function getApiWorkoutUserUserid(userId: number): Promise<Workouts> {
    const url = new URL(`/api/workout/user/${userId}`, baseUrl);
    return fetchAndParse<Workouts>(url.toString(), { method: "GET", headers });
}
export function postApiCustomworkout(body?: CustomWorkoutPayload): Promise<Workout> {
    const url = new URL(`/api/custom-workout`, baseUrl);
    return fetchAndParse<Workout>(url.toString(), { method: "POST", body: JSON.stringify(body), headers });
}
export function postApiWorkout(body?: WorkoutPayload): Promise<Workout> {
    const url = new URL(`/api/workout`, baseUrl);
    return fetchAndParse<Workout>(url.toString(), { method: "POST", body: JSON.stringify(body), headers });
}
export function postApiCustomworkoutList(body?: CustomWorkouts): Promise<CustomWorkoutsResponse> {
    const url = new URL(`/api/custom-workout/list`, baseUrl);
    return fetchAndParse<CustomWorkoutsResponse>(url.toString(), { method: "POST", body: JSON.stringify(body), headers });
}
export function postApiCustomworkoutWorkoutid(workoutId: number, body?: CustomWorkoutUpdatePayload): Promise<Workout> {
    const url = new URL(`/api/custom-workout/${workoutId}`, baseUrl);
    return fetchAndParse<Workout>(url.toString(), { method: "POST", body: JSON.stringify(body), headers });
}
export function deleteApiCustomworkoutWorkoutid(workoutId: number): Promise<boolean> {
    const url = new URL(`/api/custom-workout/${workoutId}`, baseUrl);
    return fetchAndParse<boolean>(url.toString(), { method: "DELETE", headers });
}
export function putApiWorkoutWorkoutidPicture(workoutId: number, picture: File): Promise<PictureResponse> {
    const localHeaders = { ...headers };
    delete localHeaders["Content-Type"];
    const data = new FormData();
    appendFormData(data, "picture", picture);
    const url = new URL(`/api/workout/${workoutId}/picture`, baseUrl);
    return fetchAndParse<PictureResponse>(url.toString(), { method: "PUT", body: data, headers: localHeaders });
}
