import React, { useState } from 'react'
import { useNavigate } from 'react-router';
import { createTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { useLoadData } from '../utils';
import { getApiAssessmentAssessmentid, postApiAssessment, putApiAssessmentAssessmentid } from '../services/assessment';
import { Form, Formik } from 'formik';
import { urls, route } from '../route';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Assessment } from '../models/Assessment';
import * as Yup from 'yup';
import { PartialBy } from '../services/utils';
import { useParams } from 'react-router-dom';

const validateForm = Yup.object().shape({
    dependencyMark: Yup.number().min(0, 'Value must be 0 or greater than 0'),
    name: Yup.string().min(1, 'Name too short').max(100, 'Name too long'),

})
export const AssessementEdit = () => {
    const theme = createTheme();
    const history = useNavigate();
    const p = useParams();
    const [assessement, setAssessement] = useState<Assessment>();
    const [url, setUrl] = useState("")
    useLoadData(async () => {
        if (p.id) {
            const result = await getApiAssessmentAssessmentid(+p.id);
            setAssessement(result);
            const blob = new Blob([result.content!], { type: "text/html" })
            const url = URL.createObjectURL(blob)
            setUrl(url)
        } else {
            setAssessement({
                index: 0,
                priority: 0,
                name: "",
                content: "",
                failureMark: 0,
                successMark: 0,
                dependencyMark: 0,
                dependencyId: 1,
                mediaKey: "",
                recommendationHeadline: "",
                workoutId: 1,
                id: 0,

            });
        }
    });
    async function onSubmit(e: PartialBy<Assessment, 'id'>) {
        delete e.id;
        if (p.id) {
            await putApiAssessmentAssessmentid(+p.id, e);
        } else {
            await postApiAssessment(e);
        }
        history(route(urls.assessementList));
    }
    if (!assessement) {
        return <div></div>;
    }

    return <Container>
        <Paper style={{ padding: theme.spacing(3, 2) }}>
            <Formik<Assessment>
                initialValues={assessement}
                onSubmit={onSubmit}
                validationSchema={validateForm}
            >
                {({ values, handleChange, setValues, errors, touched, setFieldValue }) => {
                    function handleChangeHTML(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
                        const value = event.target.value
                        const blob = new Blob([value], { type: "text/html" })
                        const url = URL.createObjectURL(blob)
                        setUrl(url)
                        setValues({ ...values, content: event.target.value })
                    }
                    return (
                        <Form>
                            <Grid container spacing={3} justifyContent="space-between">
                                <Grid item xs={12}>
                                    <Typography variant="h4" gutterBottom>
                                        {p.id ? `Edit ${assessement.name}` : "Add new Assessement"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={6} style={{ marginBottom: 20 }}>
                                        <Grid item sm={4}>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                        <Grid item sm={4}>
                                            <Button
                                                onClick={e => {
                                                    history(route(urls.assessementList));
                                                }}
                                                fullWidth
                                                variant="outlined"
                                                color="primary"
                                            >
                                                Back
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} justifyContent="space-between">
                                <Grid item sm={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Name"
                                        variant="outlined"
                                        id={"name"}
                                        onChange={handleChange}
                                        value={values.name}
                                        error={(errors.name && touched.name) ? true : false}
                                        helperText={errors.name ? errors.name : null}
                                    />
                                </Grid>
                                <Grid item sm={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        type="number"
                                        variant="outlined"
                                        label="Priority"
                                        id={"priority"}
                                        value={values.priority}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item sm={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        type="number"
                                        label="Index"
                                        variant="outlined"
                                        id={"index"}
                                        value={values.index}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        label="Failure Mark"
                                        type="number"
                                        variant="outlined"
                                        id={"failureMark"}
                                        onChange={handleChange}
                                        error={(errors.failureMark && touched.failureMark) ? true : false}
                                        helperText={errors.failureMark ? errors.failureMark : null}
                                        value={values.failureMark ? values.failureMark : 0}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        variant="outlined"
                                        label="Succes Mark"
                                        id={"successMark"}
                                        onChange={handleChange}
                                        error={(errors.successMark && touched.successMark) ? true : false}
                                        helperText={errors.successMark ? errors.successMark : null}
                                        value={values.successMark ? values.successMark : 0}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="Dependency Mark"
                                        variant="outlined"
                                        id={"dependencyMark"}
                                        onChange={handleChange}
                                        error={(errors.dependencyMark && touched.dependencyMark) ? true : false}
                                        helperText={errors.dependencyMark ? errors.dependencyMark : null}
                                        value={values.dependencyMark ? values.dependencyMark : 0}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        label="Dependency Id"
                                        type="number"
                                        variant="outlined"
                                        id={"dependencyId"}
                                        onChange={e => {
                                            setFieldValue("dependencyId", e.target.value.length ? parseInt(e.target.value) !== 0 ? e.target.value : null : null)
                                        }}
                                        value={values.dependencyId ? values.dependencyId : ""}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        label="Workout Id"
                                        type="number"
                                        variant="outlined"
                                        value={values.workoutId}
                                        id={"workoutId"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}></Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        required
                                        variant="outlined"
                                        label="Media ID"
                                        id={"mediaKey"}
                                        value={values.mediaKey}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        required
                                        label="Download Url"
                                        variant="outlined"
                                        id={"downloadUrl"}
                                        value={values.downloadUrl}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        required
                                        label="Recommendation Headline"
                                        variant="outlined"
                                        id={"recommendationHeadline"}
                                        value={values.recommendationHeadline}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6} >
                                            <TextField required value={values.content} onChange={handleChangeHTML} variant="outlined" multiline fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <iframe title="htmlShow" src={url} width="100%" height="100%" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        </Paper>
    </Container >
};