import { Progressions } from "../models/Progressions";
import { ProgressionSchema } from "../models/ProgressionSchema";
import { baseUrl, headers } from "./config";
import { fetchAndParse, addQueryParameter } from "./utils";
export function getApiWorkout_progressionList(): Promise<Progressions> {
    const url = new URL(`/api/workout_progression/list`, baseUrl);
    return fetchAndParse<Progressions>(url.toString(), { method: "GET", headers });
}
export function getApiWorkout_progressionProgressionid(progressionId: number): Promise<ProgressionSchema> {
    const url = new URL(`/api/workout_progression/${progressionId}`, baseUrl);
    return fetchAndParse<ProgressionSchema>(url.toString(), { method: "GET", headers });
}
